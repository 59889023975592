.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.refund-logo-btn {
  position: relative;
}

.refund-logo-btn::before {
  content: 'Refund';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 2px 4px;
  background-color: #e3e3e3;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s;
  margin-bottom: 5px;
}

.refund-logo-btn {
  position: relative;
}

.refund-logo-btn:hover::before {
  visibility: visible;
  opacity: 1;
}

.download-receipt-logo-btn {
  position: relative;
}

.download-receipt-logo-btn::before {
  content: 'Download Receipt';
  width: max-content;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 2px 4px;
  background-color: #e3e3e3;
  border-radius: 4px;
  font-size: 13px;
  color: #333;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s;
  margin-bottom: 5px;
}

.download-receipt-logo-btn:hover::before {
  visibility: visible;
  opacity: 1;
}

.icon-btn-disabled {
  opacity: 0.5;
}

.icon-btn-disabled::before {
  display: none;
}

.unsubscribe-logo-btn {
  position: relative;
}

.unsubscribe-logo-btn::before {
  content: 'Unsubscribe';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 2px 4px;
  background-color: #e3e3e3;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s;
  margin-bottom: 5px;
}

.unsubscribe-logo-btn:hover::before {
  visibility: visible;
  opacity: 1;
}

/*Hide cancel search icon in search input*/
.cbh-search::-webkit-search-cancel-button {
  display: none;
}

.manage-subscription-btn {
  position: relative;
}

.manage-subscription-btn::before {
  content: 'Move renewal date';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 2px 4px;
  background-color: #e3e3e3;
  border-radius: 4px;
  color: #333;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s;
  margin-bottom: 5px;
  font-size: 12px;
  text-wrap: nowrap;
  width: auto;
}

.manage-subscription-btn:hover::before {
  visibility: visible;
  opacity: 1;
}

.formatted-string {
  position: relative;
  display: flex;
}

#formattedText {
  padding: 5px;
  border: none;
  background-color: #f5f5f5;
  font-family: Arial, sans-serif;
  font-size: 14px;
  width: 160px;
}

.copy-icon {
  position: absolute;
  left: 90px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  cursor: pointer;
}

.fade-out {
  position: absolute;
  top: 164%;
  left: 67%;
  transform: translate(-50%, -50%);
  background-color: #d4d8df;
  color: rgb(107 114 128);
  padding: 4px;
  border-radius: 4px;
  z-index: 9999;
  font-size: 10px;
  opacity: 1;
  transition: opacity 0.5s ease;
}

.vertical-devider {
  height: auto;
  width: 1px;
  background: rgb(206 208 211);
  margin: 0 50px;
}

.react-datepicker__input-container > input {
  outline-color: #5050a9;
  outline-style: auto;
  padding-left: 8px;
}

.toggle-bg:after {
    content: '';
    @apply absolute top-0.5 left-0.5 bg-white border border-gray-300 rounded-full h-5 w-5 transition shadow-sm;
}
input:checked + .toggle-bg:after {
    transform: translateX(100%);
    @apply border-white;
}
input:checked + .toggle-bg {
    @apply bg-lime-700 border-lime-700;
}