@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

:root {
  --border-default-color: #c2c8d0;
}

.or {
  flex: 0.2 0 auto;
  margin: 0;
  text-align: center;
}

.or-separator {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 24px 0 0;
  padding: 2px 0 0 0;
  /*margin: 0;*/
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  border: none;
}

.or-separator:after,
.or-separator:before {
  flex: 1 0 auto;
  height: 0.5em;
  margin: 0;
  content: '';
  border-bottom: 1px solid #c2c8d0;
  border-bottom: 1px solid var(--border-default-color);
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

.cbh-hidden {
  visibility: hidden;
  position: absolute;
  top: -100000000px;
}

.failed-trx {
  background-color: rgba(255, 210, 210, 0.3);
}

.failed-trx:hover {
  background-color: rgba(255, 210, 210, 0.4);
}

.successful-trx {
  background-color: rgba(6, 140, 4, 0.1);
}

.successful-trx:hover {
  background-color: rgba(6, 140, 4, 0.2);
}

.react-datetime-picker__wrapper input {
  outline: none;
}

.react-datetime-picker__wrapper .react-datetime-picker__inputGroup {
  min-width: 170px;
}